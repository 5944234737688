<template>
  <div class="flex flex-col items-center">
    <img class="w-60 h-60" alt="yarn logo" src="./assets/yarn.png" />
    <p>Happy Birthday</p>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
